import React from "react";

import { useDispatch } from "react-redux";
import { actions } from "../../redux/appSetting/actions";

import { Slide, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

export interface IMessageProps {
  message?: string;
  severity?: "success" | "error" | "warning" | "info";
}

const Message: React.FC<IMessageProps> = ({ message, severity }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(actions.setToast({ message: "", severity }));
  };

  return (
    <Snackbar
      open={!!message}
      onClose={handleClose}
      autoHideDuration={5000}
      TransitionComponent={Slide}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert elevation={5} variant="filled" severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export { Message };
