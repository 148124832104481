/* eslint-disable no-useless-computed-key */
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type from "@material-ui/lab/themeAugmentation";
import { arialRounded } from "../helper/fontsPack";
import { mainPalette } from "./mainPaletteTheme";

const defaultTheme = createMuiTheme();

let theme = createMuiTheme({
  palette: mainPalette,
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [arialRounded],
        ["@media print"]: {
          ["@page"]: {
            margin: "10mm 15mm 120mm 15mm",
          },
          body: {
            color: "#000",
            margin: "10mm 15mm 10mm 15mm",

            "& .wrapper": {
              height: "100%",
              width: "100%",
              overflow: "visible",
              display: "block",
            },

            "& h4": {
              fontSize: 20,
            },
            "& span, & h6, & p": {
              fontSize: 18,
            },
            "& img": {
              width: "100%",
            },
            "& img.logo": {
              width: "120px",
              height: "auto",
              marginBottom: "0.5cm",
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      input: {
        marginTop: -17,
      },
    },
    MuiAvatar: {
      img: {
        objectPosition: "top",
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
        backgroundColor: defaultTheme.palette.common.white + "!important",
        borderRadius: 8,
      },
    },
    MuiInputBase: {
      root: {
        height: 43,
      },
      input: {
        height: 43,
        borderRadius: 8,
        padding: defaultTheme.spacing(0, 2),
        backgroundColor: defaultTheme.palette.common.white + "!important",
      },
      formControl: {
        "&.Mui-focused": {
          "& .PrivateNotchedOutline-legend-18": {
            maxWidth: 1000,
            transition: "max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms",
            width: "90px !important",
          },
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 15px) scale(1)",
      },
      formControl: {
        transform: "translate(14px, 15px) scale(1)",
        zIndex: 10,
      },
      shrink: {
        transform: "translate(14px, -6px) scale(0.75)",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: defaultTheme.spacing(0, 2),
      },
      multiline: {
        height: "auto",
      },
      notchedOutline: {},
    },
    MuiFormLabel: {
      root: {
        fontSize: 14,
        color: "rgba(93, 98, 104, 0.5)",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: mainPalette.table?.headFontColor + "!important",
        "&:hover": {
          color: "rgba(255, 255, 255, 0.8)",
        },
        "&:focus": {
          color: mainPalette.table?.headFontColor + "!important",
        },
      },
      active: {
        color: mainPalette.table?.headFontColor + "!important",
      },
      icon: {
        color: mainPalette.table?.headFontColor + "!important",
      },
    },
    MuiTableHead: {
      root: {
        fontFamily: arialRounded.fontFamily,
      },
    },
    MuiTableRow: {
      root: {
        transition: "0.5s background",
        "&:hover": {
          background: defaultTheme.palette.action.hover,
        },
      },
    },
    MuiTableCell: {
      root: {
        whiteSpace: "nowrap",
      },
      head: {
        fontFamily: arialRounded.fontFamily,
        color: mainPalette.table?.headFontColor,
        textAlign: "center",
      },
      body: {
        color: mainPalette.table?.bodyFontColor,
        fontSize: "14px",
        fontStyle: "normal",
        fontStretch: "normal",
        fontWeight: "normal",
        textAlign: "center",
        "& svg": {
          fontSize: "25px",
        },
        "& .MuiButton-root": {
          background: mainPalette.table?.actButtonColor,
          color: mainPalette.table?.headFontColor,
          fontSize: "12px",
          borderRadius: "5px",
          textTransform: "capitalize",
          height: 22,
          width: 75,
          fontWeight: "bold",
          "&:hover": {
            background: mainPalette.table?.actButtonColor,
          },
        },
      },
    },
  },
  shape: { borderRadius: 8 },
  typography: {
    fontFamily: defaultTheme.typography.fontFamily,
    allVariants: {
      color: mainPalette.typographyColor,
    },
    h1: {
      fontFamily: arialRounded.fontFamily,
      fontSize: "1.875rem",
    },
  },
});

theme = responsiveFontSizes(theme);

export { theme };
