import { SET_TOAST, IAppSettingState, AppSettingActionTypes } from "./types";

const initialState: IAppSettingState = {
  // theme: { color: "redTheme", mode: "light" },
  toast: { message: "", severity: undefined },
};

const appSettingReducer = (
  state = initialState,
  action: AppSettingActionTypes
) => {
  switch (action.type) {
    // case CHANGE_THEME:
    //   return {
    //     ...state,
    //     theme: action.theme,
    //   };
    case SET_TOAST:
      return {
        ...state,
        toast: {
          message: action.toast.message,
          severity: action.toast.severity,
        },
      };

    default:
      return state;
  }
};

export default appSettingReducer;
