import React, { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { AppState } from "./redux/store";

import "./helper/translate";
import { cookieStore } from "./helper/localStorage";

import { CssBaseline } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";

import { Message } from "./components/message";
import { Loading } from "./components/loading/fullscreen";

import { theme } from "./theme";
import "fontsource-roboto";

const Dashboard = lazy(() => import("./pages/dashboard"));
const Login = lazy(() => import("./pages/login"));

const Router = () => {
  const { message, severity } = useSelector((state: AppState) => {
    return {
      message: state.AppSetting.toast.message,
      severity: state.AppSetting.toast.severity,
    };
  });

  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Message message={message} severity={severity} />
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path={["/", "/login"]} component={Login} />
            <PrivateRoute path="/panel" component={Dashboard} />
            <Redirect to="/" />
          </Switch>
        </Suspense>
      </MuiThemeProvider>
    </BrowserRouter>
  );
};

interface IPropsPrivateRoute {
  component: React.FC<any>;
  [rest: string]: any;
}

const PrivateRoute: React.FC<IPropsPrivateRoute> = ({
  component: Component,
  ...rest
}) => {
  const isLoggedIn = !!cookieStore.get("auth-token");
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default Router;
