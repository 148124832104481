import {
  SET_TOAST,

  // CHANGE_THEME,
  IAppSettingActions,
} from "./types";

const actions: IAppSettingActions = {
  // changeTheme: (theme) => ({
  //   type: CHANGE_THEME,
  //   theme,
  // }),
  setToast: (toast) => ({
    type: SET_TOAST,
    toast,
  }),
};

export { actions };
