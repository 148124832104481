import { IPalette } from "./IPalette";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    contentBackground: string;
    boxBackground: string;
    table: {
      bodyFontColor: string;
      headFontColor: string;
      actButtonColor: string;
    };
    statusColors: {
      answered: string;
      close: string;
      toDiagnosis: string;
      toDiagnosisMoreThan4: string;
      toAnswer: string;
      toAssign: string;
    };
    typographyColor: string;
  }
}

export const mainPalette: IPalette = {
  primary: {
    main: "#115293",
  },
  contentBackground: "#f0f0f0",
  boxBackground: "#fff",
  table: {
    headFontColor: "#f0f0f0",
    bodyFontColor: "#707070",
    actButtonColor: "#909eb0",
  },
  statusColors: {
    answered: "#1fabb8",
    close: "#909eb0",
    toDiagnosis: "#e4645a",
    toAnswer: "#e63d97",
    toAssign: "#d30000",
    toDiagnosisMoreThan4: "#e3a90a",
  },
  typographyColor: "#707070",
};
