import { createStyles, makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) =>
  createStyles({
    loading: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1100,
      height: "100%",
      position: "fixed",

      backgroundColor: theme.palette.background.paper,

      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    lottie: {
      width: 320,
      maxWidth: "60%",
    },
  })
);

export { useStyle };
