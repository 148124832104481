import i18next from "i18next";
import XHR from "i18next-xhr-backend";
import Backend from "i18next-chained-backend";
import { initReactI18next } from "react-i18next";
import LocalStorageBackend from "i18next-localstorage-backend";

i18next
  .use(Backend)
  .use(initReactI18next)
  .init(
    {
      backend: {
        backends: [LocalStorageBackend, XHR],
        backendOptions: [
          {
            prefix: "app_",
            expirationTime: 0, //7 * 24 * 60 * 60 * 1000,
            store: window.localStorage,
            versions: {
              en: "1.1.0",
            },
          },
          {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
          },
        ],
      },
      fallbackLng: false,
      lng: "en",
      ns: ["public", "errorCodes"],
      defaultNS: "public",
      debug: process.env.NODE_ENV === "production" ? false : true,
    },
    (err, t) => {
      if (err) return console.error("something went wrong loading", err);
    }
  );

export default i18next;
