import React from "react";
import Lottie from "lottie-react";

import { Box } from "@material-ui/core";
import { useStyle } from "./loading.style";
import jsonLoading from "../../../assets/loading/loading.json";

const Loading = () => {
  const classes = useStyle();

  return (
    <Box className={classes.loading}>
      <Lottie animationData={jsonLoading} className={classes.lottie} />
    </Box>
  );
};

export { Loading };
