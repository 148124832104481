import ArialRoundedMTBoldTTF from "../assets/fonts/ArialRoundedMTBold.ttf";
import ArialRoundedMTBoldWOFF from "../assets/fonts/ArialRoundedMTBold.woff";
import ArialRoundedMTBoldWOFF2 from "../assets/fonts/ArialRoundedMTBold.woff2";

export const arialRounded = {
  fontFamily: "ArialRounded",
  fontStyle: "normal",
  fontWeight: 700,
  src: `
  url(${ArialRoundedMTBoldWOFF2}) format('woff2'),
  url(${ArialRoundedMTBoldWOFF}) format('woff'),
  url(${ArialRoundedMTBoldTTF}) format('truetype')
  `,
};
