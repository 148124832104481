// import { IPalette, themeMode } from "../../theme/main/IPalette";
import { IMessageProps } from "../../components/message";

export const CHANGE_THEME = "CHANGE_THEME";
export const SET_LOADING = "SET_LOADING";
export const SET_DRAWER = "SET_DRAWER";
export const SET_TOAST = "SET_TOAST";

export interface IAppSettingState {
  // theme: { color: keyof IPalette; mode: themeMode };
  toast: { message: string; severity: IMessageProps["severity"] };
}

// interface IChangeTheme {
//   type: typeof CHANGE_THEME;
//   theme: IAppSettingState["theme"];
// }

interface ISetToast {
  type: typeof SET_TOAST;
  toast: IAppSettingState["toast"];
}

export interface IAppSettingActions {
  // changeTheme: (theme: IAppSettingState["theme"]) => IChangeTheme;
  setToast: (toast: IAppSettingState["toast"]) => ISetToast;
}

export type AppSettingActionTypes = ISetToast;
